import MenuIcon from '@mui/icons-material/Menu'
import { Link, AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar } from '@mui/material'
import * as React from 'react'

import { ReactComponent as Logo } from '../logo.svg'

const navItems = [
  { title: 'About', link: '/about' },
  { title: 'Contact', link: '/contact' },
]

const NavigationBar = (): JSX.Element => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = (): void => {
    setAnchorElNav(null)
  }

  return (
    <AppBar position='fixed' sx={{ backgroundColor: 'white' }}>
      <Container maxWidth='lg'>
        <Toolbar disableGutters>
          {/* pc */}
          <Link
            href='/'
            sx={{ fill: (theme) => theme.palette.primary.main, display: { xs: 'none', md: 'flex' }, mr: 1 }}
          >
            <Logo height={55} width={60} />
          </Link>
          <Box
            sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: { xs: 'none', md: 'flex-end' } }}
          >
            {navItems.map(({ title, link }) => (
              <Button key={link} onClick={handleCloseNavMenu} sx={{ my: 2, display: 'block' }}>
                <Link href={link} color='primary' underline='none'>
                  {title}
                </Link>
              </Button>
            ))}
          </Box>
          {/* mobile */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='primary'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {navItems.map(({ title, link }) => (
                <MenuItem key={link} onClick={handleCloseNavMenu}>
                  <Link href={link} color='primary' underline='none'>
                    {title}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Link
            href='/'
            sx={{
              fill: (theme) => theme.palette.primary.main,
              display: { xs: 'flex', md: 'none' },
              mr: 2,
              flexGrow: 1,
            }}
          >
            <Logo height={55} width={60} />
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default NavigationBar
