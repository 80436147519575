import { Link, Typography } from '@mui/material'
import React from 'react'

import HeadLine1 from '../component/HeadLine1'
import HeadLine2 from '../component/HeadLine2'

const Contact: React.FC = () => {
  return (
    <>
      <HeadLine1>プライバシーポリシー</HeadLine1>
      <HeadLine2>基本方針</HeadLine2>
      <Typography>
        当社ウェブサイト及び当社の提供するアプリ（以下「本サービス」といいます）は、個人情報の重要性を認識し、
        個人情報を保護することが社会的責務であると考え、個人情報に関する法令を遵守し、
        本サービスで取扱う個人情報の取得、利用、管理を適正に行います。
      </Typography>
      <HeadLine2>適用範囲</HeadLine2>
      <Typography>
        本プライバシーポリシーは、お客様の個人情報もしくはそれに準ずる情報を取り扱う際に、本サービスが遵守する方針を示したものです。
      </Typography>
      <HeadLine2>個人情報の利用目的</HeadLine2>
      <Typography>本サービスは、お客様からご提供いただく情報を以下の目的の範囲内において利用します。</Typography>
      <ul>
        <li>ご本人確認のため</li>
        <li>お問い合わせ、コメント等の確認・回答のため</li>
        <li>メールマガジン・DM・各種お知らせ等の配信・送付のため</li>
        <li>キャンペーン・アンケート・モニター・取材等の実施のため</li>
        <li>サービスの提供・改善・開発・マーケティングのため</li>
        <li>お客さまの承諾・申込みに基づく、提携事業者・団体等への個人情報の提供のため</li>
        <li>利用規約等で禁じている行為などの調査のため</li>
        <li>その他個別に承諾いただいた目的</li>
      </ul>
      <HeadLine2>個人情報の管理</HeadLine2>
      <Typography>
        本サービスは、個人情報の正確性及び安全確保のために、セキュリティ対策を徹底し、個人情報の漏洩、改ざん、
        不正アクセスなどの危険については、必要かつ適切なレベルの安全対策を実施します。
      </Typography>
      <Typography>
        本サービスは、第三者に重要な情報を読み取られたり、改ざんされたりすることを防ぐために、SSLによる暗号化を使用しております。
      </Typography>
      <HeadLine2>個人情報の第三者提供</HeadLine2>
      <Typography>
        本サービスは、以下を含む正当な理由がある場合を除き、個人情報を第三者に提供することはありません。
      </Typography>
      <ul>
        <li>ご本人の同意がある場合</li>
        <li>法令に基づく場合</li>
        <li>人の生命・身体・財産の保護に必要な場合</li>
        <li>公衆衛生・児童の健全育成に必要な場合</li>
        <li>国の機関等の法令の定める事務への協力の場合（税務調査、統計調査等）</li>
      </ul>
      <Typography>
        本サービスでは、利用目的の達成に必要な範囲内において、他の事業者へ個人情報を委託することがあります。
      </Typography>
      <HeadLine2>個人情報に関するお問い合わせ</HeadLine2>
      <Typography>
        開示、訂正、利用停止等のお申し出があった場合には、所定の方法に基づき対応致します。
        具体的な方法については、個別にご案内しますので、お問い合わせください。
      </Typography>
      <HeadLine2>Cookie（クッキー）</HeadLine2>
      <Typography>
        Cookie（クッキー）は、利用者のサイト閲覧履歴を、利用者のコンピュータに保存しておく仕組みです。
      </Typography>
      <Typography>
        利用者はCookie（クッキー）を無効にすることで収集を拒否することができますので、お使いのブラウザの設定をご確認ください。
        ただし、Cookie（クッキー）を拒否した場合、本サービスのいくつかのサービス・機能が正しく動作しない場合があります。
      </Typography>
      <HeadLine2>アクセス解析</HeadLine2>
      <Typography>
        本サービスでは、サイトの分析と改善のためにGoogleが提供している「Google アナリティクス」を利用しています。
      </Typography>
      <Typography>
        このサービスは、トラフィックデータの収集のためにCookie（クッキー）を使用しています。
        トラフィックデータは匿名で収集されており、個人を特定するものではありません。
      </Typography>
      <HeadLine2>広告配信</HeadLine2>
      <Typography>本サービスは、第三者配信の広告サービス「Google アドセンス」を利用しています。</Typography>
      <Typography>
        広告配信事業者は、利用者の興味に応じた広告を表示するためにCookie（クッキー）を使用することがあります。
        これによって利用者のブラウザを識別できるようになりますが、個人を特定するものではありません。
      </Typography>
      <Typography>
        Cookie（クッキー）を無効にする方法や「Google アドセンス」に関する詳細は、
        <Link href='https://policies.google.com/technologies/ads?gl=jp'>
          https://policies.google.com/technologies/ads?gl=jp
        </Link>
        をご覧ください。
      </Typography>
      <Typography>また、Amazonのアソシエイトとして、本サービスは適格販売により収入を得ています。</Typography>
      <HeadLine2>コメント・お問い合わせフォーム</HeadLine2>
      <Typography>
        本サービスでは、コメント・お問い合わせフォームに表示されているデータ、そしてスパム検出に役立てるための IP
        アドレスやブラウザのユーザーエージェント文字列等を収集します。
      </Typography>
      <Typography>
        メールアドレスから作成される匿名化されたハッシュ文字列は、
        あなたが「Gravatar」サービスを使用中かどうか確認するため同サービスに提供されることがあります。
      </Typography>
      <Typography>
        同サービスのプライバシーポリシーは、
        <Link href='https://automattic.com/privacy/'>https://automattic.com/privacy/</Link> をご覧ください。
      </Typography>
      <Typography>なお、コメントが承認されると、プロフィール画像がコメントとともに一般公開されます。</Typography>
      <HeadLine2>他サイトからの埋め込みコンテンツ</HeadLine2>
      <Typography>
        本サービスには、埋め込みコンテンツ（動画、画像、投稿など）が含まれます。
        他サイトからの埋め込みコンテンツは、訪問者がそのサイトを訪れた場合とまったく同じように振る舞います。
      </Typography>
      <Typography>
        これらのサイトは、あなたのデータの収集、Cookie（クッキー）の使用、サードパーティによる追加トラッキングの埋め込み、
        埋め込みコンテンツとのやりとりの監視を行うことがあります。
      </Typography>
      <Typography>
        アカウントを使ってそのサイトにログイン中の場合、埋め込みコンテンツとのやりとりのトラッキングも含まれます。
      </Typography>
      <HeadLine2>免責事項</HeadLine2>
      <Typography>
        本サービスのコンテンツ・情報について、可能な限り正確な情報を掲載するよう努めておりますが、正確性や安全性を保証するものではありません。
        本サービスに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
      </Typography>
      <Typography>
        本サービスからリンクやバナーなどによって他のサイトに移動した場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。
      </Typography>
      <Typography>本サービスで掲載している料金表記について、予告なく変更されることがあります。</Typography>
      <HeadLine2>著作権・肖像権</HeadLine2>
      <Typography>
        本サービスで掲載しているすべてのコンテンツ（文章、画像、動画、音声、ファイル等）の著作権・肖像権等は
        本サービス所有者または各権利所有者が保有し、許可なく無断利用（転載、複製、譲渡、二次利用等）することを禁止します。
        また、コンテンツの内容を変形・変更・加筆修正することも一切認めておりません。
      </Typography>
      <Typography>
        各権利所有者におかれましては、万一掲載内容に問題がございましたら、ご本人様よりお問い合わせください。迅速に対応いたします。
      </Typography>
      <HeadLine2>リンク</HeadLine2>
      <Typography>
        本サービスは原則リンクフリーです。リンクを行う場合の許可や連絡は不要です。
        引用する際は、引用元の明記と該当ページへのリンクをお願いします。
      </Typography>
      <Typography>
        ただし、画像ファイルへの直リンク、インラインフレームを使用した HTML
        ページ内で表示する形でのリンクはご遠慮ください。
      </Typography>
      <HeadLine2>本プライバシーポリシーの変更</HeadLine2>
      <Typography>本サービスは、本プライバシーポリシーの内容を適宜見直し、その改善に努めます。</Typography>
      <Typography>本プライバシーポリシーは、事前の予告なく変更することがあります。</Typography>
      <Typography>本プライバシーポリシーの変更は、本サービスに掲載された時点で有効になるものとします。</Typography>
    </>
  )
}

export default Contact
