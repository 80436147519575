import { Box, Container, createTheme, CssBaseline, ThemeOptions, ThemeProvider, Toolbar } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'

import Footer from './Footer'
import NavigationBar from './NavigationBar'

const Layout: React.FC = () => {
  const theme = createTheme(themeOptions)

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <CssBaseline />
        <NavigationBar />
        <Toolbar>{/* コンテンツが Navbar と重ならないようにするため */}</Toolbar>
        <Container component='main' maxWidth='md' sx={{ mb: 10 }}>
          <Outlet />
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  )
}

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#18216d',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ff825c',
      contrastText: '#ffffff',
    },
    background: {
      default: '#ffffff',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Noto Sans',
      fontWeight: 600,
    },
    fontFamily: 'Noto Sans',
  },
}

export default Layout
