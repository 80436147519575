import { TypographyProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import * as React from 'react'

const HeadLine1: React.FC<TypographyProps> = (props) => (
  <Typography component='h1' variant='h4' sx={{ fontWeight: 'bold', mt: 6, mb: 3 }}>
    {props.children}
  </Typography>
)

export default HeadLine1
