import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'

const Contact: React.FC = () => {
  return (
    <>
      <Paper
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 4,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${'/images/contact.jpg'})`,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
          }}
        />
        <Grid container>
          <Grid item md={6}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 6 },
                pr: { md: 0 },
              }}
            >
              <Typography component='h1' variant='h3' color='inherit' gutterBottom>
                CONTACT US
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Box>
        <Typography component='h2' variant='h5' sx={{ my: 3 }}>
          お問い合わせは下記まで
        </Typography>
        <Typography color='text.primary'>e-mail: exor.companymail@gmail.com</Typography>
      </Box>
    </>
  )
}

export default Contact
