import { Link } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import * as React from 'react'

export interface Props {
  name: string
  imagePath: string
  playStoreLink?: string
  appStoreLInk?: string
}

const AppCard: React.FC<Props> = (props) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia component='img' alt='application image' height='140' image={props.imagePath} />
      <CardContent>
        <Typography gutterBottom variant='h5' component='div'>
          {props.name}
        </Typography>
      </CardContent>
      <CardActions>
        {Boolean(props.playStoreLink) && (
          <Link href={props.playStoreLink} target='_blank'>
            <img height={'48px'} alt='Get it on Google Play' src='/google-play-badge.png' />
          </Link>
        )}
        {Boolean(props.appStoreLInk) && (
          <Link href={props.appStoreLInk} sx={{ paddingRight: 1 }} target='_blank'>
            <img height={'32px'} alt='Get it on App Store' src='/app-store-badge.svg' />
          </Link>
        )}
      </CardActions>
    </Card>
  )
}

export default AppCard
