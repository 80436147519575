import { Box, Grid, Paper, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'

import AppCard from '../component/AppCard'
import HeadLine2 from '../component/HeadLine2'

const Top: React.FC = () => {
  return (
    <>
      <Paper
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 4,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${'/images/top.png'})`,
          width: '100vw',
          ml: `calc(${'50% - 50vw'})`,
          mr: `calc(${'50% - 50vw'})`,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
          }}
        />
        <Container maxWidth='md'>
          <Grid container>
            <Grid item md={6}>
              <Box
                sx={{
                  position: 'relative',
                  p: { xs: 3, md: 6 },
                  px: { md: 0 },
                }}
              >
                <Typography component='h1' variant='h3' color='inherit' gutterBottom>
                  Enhance the Lives by Apps
                </Typography>
                <Typography variant='h5' color='inherit' paragraph>
                  アプリで生活を豊かに
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Box>To develop innovative apps that enhance the lives and connections of us.</Box>
      <HeadLine2>Products</HeadLine2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <AppCard
            name={'待ち時間計算'}
            imagePath={'images/queueing_theory.png'}
            playStoreLink='https://play.google.com/store/apps/details?id=jp.exorinc.queueingtheory.android&pcampaignid=web_share'
            // appStoreLInk='#'
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Top
