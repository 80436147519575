import { Box, Container, Link, Typography } from '@mui/material'
import * as React from 'react'

const Copyright = (): JSX.Element => {
  return (
    <Typography variant='body2' color='text.secondary'>
      {'Copyright © '}
      {'2023 '}
      <Link href='https://exorinc.jp' color='inherit' underline='none'>
        ExOR Inc.
      </Link>
    </Typography>
  )
}

const Footer = (): JSX.Element => {
  return (
    <Box
      component='footer'
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
      }}
    >
      <Container maxWidth='lg' sx={{ textAlign: 'right', mb: 1 }}>
        <Typography variant='body2' color='text.secondary' fontWeight='bold'>
          <Link href='/privacy' color='inherit' underline='none'>
            Privacy Policy
          </Link>
        </Typography>
      </Container>
      <Container maxWidth='lg' sx={{ textAlign: 'center' }}>
        <Copyright />
      </Container>
    </Box>
  )
}

export default Footer
