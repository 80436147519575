import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import React from 'react'

import HeadLine1 from '../component/HeadLine1'

const tableData = [
  {
    header: '会社名',
    body: '合同会社ExOR',
  },
  {
    header: '設立年月日',
    body: '2023年3月28日',
  },
  {
    header: '所在地',
    body: '東京都渋谷区神泉町10番15号',
  },
  {
    header: '事業内容',
    body: 'モバイルアプリ開発',
  },
]

const About: React.FC = () => {
  return (
    <Box>
      <HeadLine1>会社概要</HeadLine1>
      {/* for PC */}
      <TableContainer sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Table sx={{ minWidth: 650 }} aria-label='company information table'>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component='th' scope='row' sx={{ fontWeight: 'bold' }}>
                  {row.header}
                </TableCell>
                <TableCell>{row.body}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* for Mobile */}
      <TableContainer sx={{ display: { xs: 'flex', md: 'none' } }}>
        <Table
          sx={{ borderRadius: '5px', borderBottom: '1px solid', borderColor: 'grey.300', width: '100%' }}
          aria-label='company information table'
        >
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  component='th'
                  scope='row'
                  sx={{
                    backgroundColor: 'grey.200',
                    border: '1px solid',
                    borderColor: 'grey.300',
                    width: 'auto',
                    borderBottom: 'none',
                    display: 'block',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  {row.header}
                </TableCell>
                <TableCell
                  sx={{
                    border: '1px solid',
                    borderColor: 'grey.300',
                    width: 'auto',
                    borderBottom: 'none',
                    display: 'block',
                    textAlign: 'left',
                  }}
                >
                  {row.body}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default About
